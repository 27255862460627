import React, { PureComponent } from 'react';
import { isMobile } from 'react-device-detect';
import { withStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import Link from '@material-ui/core/Link';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import Box from '@material-ui/core/Box';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { withRouter } from 'react-router';
import Radio from '@material-ui/core/Radio';
import Tooltip from '@material-ui/core/Tooltip';
import { Grid } from '@material-ui/core';
// MUI Icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { AppButton } from '../../packages/AppButton';
import FormGroupComponent from '../../packages/AppFormGroup';
import AppAdapterLink from '../../packages/AppAdapterLink';
import FormFields from './FormField';
import { APP_CONSTANTS } from '../../constants';

import {
  convertTimeToAMPM,
  debounced,
  getQueryStringParams,
  validateForm,
  getNullForEmptyString,
} from '../../utils';
import AppBasePageCard from '../../packages/AppBasePageCard';
import QontoStepIcon, { QontoConnector } from '../../packages/Stepper/Qonto';
import InfoModal from '../../packages/AppModal/Info';
import { createAgency } from '../../modules/Agency/actions';
import { StyledLink } from '../../packages/AppLink';
import Payment from '../../components/Payment';
import {
  COUNTRY_STATES,
  COUNTRY_LABELS,
  COUNTRY_REGEX,
} from '../../data/config';
import ClientConversationPreferences from '../../components/ClientConversationPreferences';
import EmployeeScreeningPreferences from '../../components/EmployeeScreeningPreferences';

function getSteps(param) {
  const stepsWithPayment = [
    'Admin Info',
    'Organization Info',
    'Office Info',
    'Notifications',
    'Preferences',
    'Payment Info',
  ];

  const stepsWithoutPayment = [
    'Admin Info',
    'Organization Info',
    'Office Info',
    'Notifications',
    'Preferences',
  ];
  return param == null ? stepsWithPayment : stepsWithoutPayment;
}

function getRequiredFields(param) {
  const requiredFieldsWithPayment = [
    'firstName',
    'lastName',
    'email',
    'cellPhone',
    'agencyName',
    'agencyWebsite',
    'agencyCountry',
    'agencyAddress',
    'agencyCity',
    'agencyState',
    'agencyZip',
    'agencyType',
    'timeZone',
    'serviceProvided',
    'payment_option',
    'coverageArea',
    't_and_c_read',
    'client_lead_notifications_emails',
    'employee_lead_notifications_emails',
    'billing_email',
  ];

  const requiredFieldsWithoutPayment = [
    'firstName',
    'lastName',
    'email',
    'cellPhone',
    'agencyName',
    'agencyWebsite',
    'agencyCountry',
    'agencyAddress',
    'agencyCity',
    'agencyState',
    'agencyZip',
    'agencyType',
    'timeZone',
    'serviceProvided',
    'payment_option',
    'coverageArea',
    't_and_c_read',
    'client_lead_notifications_emails',
    'employee_lead_notifications_emails',
  ];
  return param == null
    ? requiredFieldsWithPayment
    : requiredFieldsWithoutPayment;
}

const Accordion = withStyles({
  root: {
    boxSizing: 'border-box',
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    boxSizing: 'border-box',
    backgroundColor: '#5EC5BA',
    color: 'white',
    fontSize: '19px',
    fontWeight: '550',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 26,
    '&$expanded': {
      minHeight: 26,
    },
  },
  content: {
    margin: '0',
    '&$expanded': {
      margin: '0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',
    boxSizing: 'border-box',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
  },
}))(MuiAccordionDetails);

const OPTIONAL_FIELDS_WITH_VALIDATION = [
  'client_lead_notifications_emails',
  'employee_lead_notifications_emails',
  'client_lead_notification_phones',
  'employee_lead_notifications_phones',
  'billing_email',
];

let myQueryParam;

class BasicRegistrationPage extends PureComponent {
  constructor(props) {
    super(props);

    const { location } = this.props;
    const queryParams = new URLSearchParams(location.search);
    myQueryParam = queryParams.get('p') === 'f' ? queryParams.get('p') : null;

    this.paymentChild = React.createRef();
    this.state = {
      heading: 'Organization Registration',
      jsonFormField: FormFields,
      formValue: {
        fromTime: '09:00',
        toTime: '17:00',
      },
      fieldError: {
        fromTime: false,
        toTime: false,
        client_lead_notifications_emails: false,
        employee_lead_notifications_emails: false,
        billing_email: false,
      },
      requiredFields: getRequiredFields(myQueryParam),
      stepWiseRequiredFields: {
        0: ['firstName', 'lastName', 'email', 'cellPhone'],
        1: [
          'agencyName',
          'agencyWebsite',
          'agencyAddress',
          'agencyCountry',
          'agencyCity',
          'agencyState',
          'agencyZip',
          'agencyType',
          'serviceProvided',
          'payment_option',
        ],
        2: ['timeZone', 'coverageArea'],
        3:
          myQueryParam == null
            ? [
                'client_lead_notifications_emails',
                'employee_lead_notifications_emails',
              ]
            : [
                'client_lead_notifications_emails',
                'employee_lead_notifications_emails',
                't_and_c_read',
              ],
        4: [],
        5: myQueryParam == null ? ['t_and_c_read', 'billing_email'] : [],
      },
      stepWiseValidForm: {
        0: false,
        1: false,
        2: false,
        3: true,
        4: true,
        5: myQueryParam !== null,
      },
      validForm: false,
      loading: false,
      activeStep: 0,
      dialogTitle: '',
      dialogOpen: false,
      dialogMsg: '',
      paymentValid: myQueryParam !== null,
      validOptionalField: false,
      registrationDone: false,
      expanded: 'clientLeadNotification',
    };
    this.doFormValidation = debounced(399, () => {
      this.setState((prevState) => ({
        validForm: validateForm({ ...prevState }),
      }));
      this.setState((prevState) => ({
        stepWiseValidForm: {
          ...prevState.stepWiseValidForm,
          [prevState.activeStep]: validateForm({
            requiredFields:
              prevState.stepWiseRequiredFields[prevState.activeStep],
            fieldError: prevState.fieldError,
          }),
        },
      }));
    });
    this.steps = getSteps(myQueryParam);
  }

  componentDidMount() {
    const { isAuthenticated } = this.props;
    if (isAuthenticated) {
      this.props.history.push(APP_CONSTANTS.APP_ROUTE_URLS.DASHBOARD);
    }
    this.updateFormFieldsWithMetaData();
  }

  componentDidUpdate() {
    this.updateFormFieldsWithMetaData();
  }

  updateFormFieldsWithMetaData() {
    const { timezones, agencyType, paymentOption } = this.props;

    FormFields[1].list[7].values = [];

    FormFields[1].list[14].values = [];
    FormFields[2].list[0].values = [];

    _.forEach(agencyType, (agencyTypeVal) => {
      FormFields[1].list[7].values.push({
        label: agencyTypeVal,
        value: agencyTypeVal,
      });
    });

    _.forEach(paymentOption, (po) => {
      FormFields[1].list[14].values.push({ label: po, value: po });
    });
    _.forEach(timezones, (tz) => {
      FormFields[2].list[0].values.push({ label: tz, value: tz });
    });

    this.setState({ jsonFormField: FormFields });
  }

  handleNext = () => {
    this.setState((prevState) => ({
      ...prevState,
      formValue: {
        ...prevState.formValue,
        client_lead_notifications_emails:
          prevState.activeStep === 0
            ? [prevState.formValue?.email]
            : prevState.formValue.client_lead_notifications_emails,
        employee_lead_notifications_emails:
          prevState.activeStep === 0
            ? [prevState.formValue?.email]
            : prevState.formValue.employee_lead_notifications_emails,
        billing_email:
          prevState.activeStep === 0
            ? prevState.formValue?.email
            : prevState.formValue.billing_email,
      },
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((prevState) => ({
      ...prevState,
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({ activeStep: 0 });
  };

  onPreferencesChange = (key, value, error) => {
    const updatedFormValue = this.state.formValue;
    this.setState((prevState) => ({
      ...prevState,
      formValue: {
        ...updatedFormValue,
        agency_preferences: {
          ...updatedFormValue?.agency_preferences,
          [key]: value,
        },
      },
      stepWiseValidForm: {
        ...prevState.stepWiseValidForm,
        [prevState.activeStep]: !error,
      },
    }));
  };

  handleChange = ({ name, value, error }) => {
    const updatedFormField = this.state.jsonFormField;
    const updatedFormValue = { ...this.state.formValue };
    const updatedFieldError = { ...this.state.fieldError };
    const {
      serviceProvided,
      serviceProvidedALF,
      serviceProvidedADC,
    } = this.props;

    let validOptionalField = false;

    if (name === 'agencyType') {
      FormFields[1].list[8].values = [];
      updatedFormField[1].list[10].hidden = false;
      updatedFormField[1].list[11].hidden = false;
      updatedFormField[1].list[12].hidden = false;
      let sp = serviceProvided;
      delete updatedFormValue?.serviceProvided;
      updatedFieldError.serviceProvided = true;
      updatedFormField[2].list[3].value = false;
      updatedFormField[2].list[1].disabled = false;
      updatedFormField[2].list[2].disabled = false;
      updatedFormValue.fromTime = '09:00';
      updatedFormValue.toTime = '17:00';

      if (_.includes(value, 'Adult Day Care Facility')) {
        sp = serviceProvidedADC;
      }

      if (_.includes(value, 'Assisted Living Facility')) {
        updatedFormField[1].list[10].hidden = true;
        updatedFormField[1].list[11].hidden = true;
        updatedFormField[1].list[12].hidden = true;
        delete updatedFormValue?.min_hours_per_day;
        delete updatedFormValue?.min_hours_per_week;
        delete updatedFormValue?.min_hours_per_visit;
        sp = serviceProvidedALF;
        updatedFormField[2].list[3].value = true;
        updatedFormField[2].list[1].disabled = true;
        updatedFormField[2].list[2].disabled = true;
        updatedFormValue.fromTime = '00:00';
        updatedFormValue.toTime = '23:59';
      }

      _.forEach(sp, (avl) => {
        FormFields[1].list[8].values.push({ label: avl, value: avl });
      });
    }

    if (name === 'agencyCountry') {
      delete updatedFormValue?.agencyState;
      delete updatedFormValue?.agencyZip;
      updatedFieldError.agencyState = true;
      updatedFieldError.agencyZip = true;
      updatedFormField[1].list[5].values = COUNTRY_STATES[value];
      updatedFormField[1].list[5].label = COUNTRY_LABELS[value].state;
      updatedFormField[1].list[6].label = COUNTRY_LABELS[value].zip;
      updatedFormField[1].list[6].regExpValue = COUNTRY_REGEX[value];
    }

    if (name === '24hour') {
      if (value === true) {
        updatedFormField[2].list[1].disabled = true;
        updatedFormField[2].list[2].disabled = true;
        updatedFormValue.fromTime = '00:00';
        updatedFormValue.toTime = '23:59';
      } else {
        updatedFormValue.fromTime = '09:00';
        updatedFormValue.toTime = '17:00';
        updatedFormField[2].list[1].disabled = false;
        updatedFormField[2].list[2].disabled = false;
      }
    }

    if (_.includes(OPTIONAL_FIELDS_WITH_VALIDATION, name)) {
      validOptionalField = error;
    }

    this.setState(
      (prevState) => ({
        ...prevState,
        validOptionalField,
        jsonFormField: updatedFormField,
        formValue: {
          ...updatedFormValue,
          [name]: value,
        },
        fieldError: {
          ...updatedFieldError,
          [name]: error,
        },
      }),
      () => {
        this.doFormValidation();
      },
    );
  };

  handelKeyDown(currentValue, event) {
    const triggeredKeyCode = event?.keyCode;
    const restrictedKeyCodes = [187, 189, 190];

    const isTriggeredKeyCodeIncluded = _.includes(
      restrictedKeyCodes,
      triggeredKeyCode,
    );

    if (currentValue?.length === 2 && triggeredKeyCode !== 8) {
      event.preventDefault();
    } else if (isTriggeredKeyCodeIncluded) {
      event.preventDefault();
    }
  }

  enableSubmit = (shouldSubmit) => {
    this.setState((prevState) => ({
      ...prevState,
      paymentValid: shouldSubmit,
    }));
  };

  formSubmitHandler = async () => {
    this.setState({ loading: true, validForm: false });

    const result =
      myQueryParam == null
        ? await this.paymentChild.current.showAlert()
        : { token: { id: undefined }, err: false };

    const { token, err } = result;
    const stripeToken = token.id;

    if (!err) {
      const formValue = { ...this.state.formValue };
      const qps = getQueryStringParams(this.props?.location?.search);
      const {
        firstName,
        lastName,
        email,
        cellPhone,
        agencyName,
        agencyWebsite,
        agencyAddress,
        agencyCity,
        agencyState,
        agencyZip,
        agencyType,
        agencyDescription,
        fromTime,
        toTime,
        timeZone,
        coverageCities,
        serviceProvided,
        t_and_c_read,
        min_hours_per_visit,
        min_hours_per_day,
        min_hours_per_week,
        agencyCountry,
        billing_email,
        coverageArea,
        payment_option,
        client_lead_notifications_emails,
        employee_lead_notifications_emails,
        billing_notifications_emails,
        client_lead_notification_phones,
        employee_lead_notifications_phones,
        agency_preferences,
      } = formValue;

      const payload = {
        agency_and_agency_admin: {
          agency: {
            name: agencyName,
            address: agencyAddress,
            country: agencyCountry,
            city: agencyCity,
            state: agencyState,
            zip_postal_code: agencyZip,
            t_and_c_accepted_date: new Date().toISOString(),
            agency_website_url: agencyWebsite,
            office_hours_from: convertTimeToAMPM(fromTime),
            office_hours_to: convertTimeToAMPM(toTime),
            office_hours_timezone: timeZone,
            coverage_area_cities: coverageCities,
            coverage_area: coverageArea,
            billing_email,
            package_type: 'BASIC',
            service_provided: {
              service_provided: serviceProvided || [],
            },
            agency_type: {
              agency_type: [`${agencyType}`] || [],
            },
            description: agencyDescription,
            affiliate: qps?.a,
            referrer: qps?.ref,
            referrer_id: qps?.cid,
            lead_notifications_emails: {
              client_lead_notifications_emails,
              employee_lead_notifications_emails,
              billing_notifications_emails,
            },
            lead_notification_phones: {
              client_lead_notification_phones,
              employee_lead_notifications_phones,
            },
            min_hours_per_visit: getNullForEmptyString(min_hours_per_visit),
            min_hours_per_week: getNullForEmptyString(min_hours_per_week),
            min_hours_per_day: getNullForEmptyString(min_hours_per_day),
            payment_option: { payment_option },
            agency_preferences,
          },
          agency_admin: {
            login_id: email,
            first_name: firstName,
            last_name: lastName,
            t_and_c_read,
            email,
            cell_phone: cellPhone,
          },
          token: stripeToken,
        },
      };

      const formData = new FormData();
      const blobData = new Blob([JSON.stringify(payload)], {
        type: 'application/json',
      });

      formData.append('agency_and_agency_admin', blobData);

      this.props
        .createAgency(formData)
        .then((resp) => {
          const {
            data: { error, message },
          } = resp;
          if (error) {
            this.setState({
              dialogTitle: 'Error while register!',
              dialogOpen: true,
              dialogMsg: message,
              loading: false,
              validForm: true,
              registrationDone: false,
            });
          } else {
            this.setState({
              dialogTitle: 'Organization registered successfully!',
              dialogOpen: true,
              dialogMsg:
                'Thank you for registering your agency. Please check your email for a message from AidQuest which includes a temporary password for your log in.',
              loading: false,
              registrationDone: true,
            });
          }
        })
        .catch(() => {
          this.setState({ loading: false, validForm: true });
        });
    }
  };

  onDropFileAction = (data) => {
    this.setState({ selectedFile: data });
  };

  handleAccordionChange = (panel) => (event, newExpanded) => {
    this.setState((prevState) => ({
      ...prevState,
      expanded: newExpanded ? panel : false,
    }));
  };

  render() {
    const {
      jsonFormField,
      heading,
      formValue,
      validForm,
      loading,
      activeStep,
      dialogTitle,
      dialogOpen,
      dialogMsg,
      stepWiseValidForm,
      paymentValid,
      // expanded,
      validOptionalField,
    } = this.state;

    return (
      <>
        <AppBasePageCard
          title={[
            'Hello!',
            'Welcome to AidQuest.',
            'Please enter your agency info to register.',
          ]}
        >
          <CardHeader title={heading} className="card-heading basic-reg" />
          <CardContent className="card-content basic-reg">
            <Stepper
              style={{ padding: isMobile ? 0 : 8 }}
              alternativeLabel
              activeStep={activeStep}
              connector={<QontoConnector />}
              className="stepper-container"
            >
              {this.steps.map((label) => (
                <Step key={label} className="stepper-step">
                  <StepLabel
                    StepIconComponent={QontoStepIcon}
                    className="stepper-label"
                  >
                    <Typography
                      variant="subtitle1"
                      className="stepper-typography"
                    >
                      {label}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>

            {jsonFormField[activeStep]?.type === 'PAYMENT' && (
              <Payment
                ref={this.paymentChild}
                enableSubmit={this.enableSubmit}
                pageType="BASIC_REG"
                newPaymentMethod
              />
            )}

            {jsonFormField[activeStep]?.type === 'PREFERENCES' && (
              <div style={{ height: 'calc(100vh - 380px)', overflowY: 'auto' }}>
                <Grid container style={{ paddingTop: '5px' }}>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <div
                      style={{
                        border: '1px solid lightgray',
                        borderRadius: '10px',
                        minHeight: '405px',
                        overflowY: 'auto',
                        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 8px',
                      }}
                    >
                      <ClientConversationPreferences
                        apiFormData={
                          this.state.formValue?.agency_preferences || {}
                        }
                        handleChange={this.onPreferencesChange}
                      ></ClientConversationPreferences>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <div
                      style={{
                        border: '1px solid lightgray',
                        borderRadius: '10px',
                        minHeight: '405px',
                        overflowY: 'auto',
                        boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 8px',
                      }}
                    >
                      <EmployeeScreeningPreferences
                        apiFormData={
                          this.state.formValue?.agency_preferences || {}
                        }
                        handleChange={this.onPreferencesChange}
                      ></EmployeeScreeningPreferences>
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}

            <form
              noValidate
              autoComplete="off"
              name="signInForm"
              className="form-container basic-reg"
            >
              <Box
                className={`form-content ${
                  jsonFormField[activeStep]?.type === 'PAYMENT' &&
                  'payment-content'
                } ${
                  jsonFormField[activeStep]?.type === 'PREFERENCES' &&
                  'preference-content'
                }`}
              >
                {jsonFormField[activeStep]?.type === 'NOTIFICATION' ? (
                  <>
                    {_.map(jsonFormField[activeStep]?.list, (fields, index) => (
                      <Accordion
                        defaultExpanded={fields.defaultExpanded}
                        key={`notification-form-group-accordion-key-${index}`}
                        // disabled={validOptionalField}
                        // expanded={expanded === fields?.accordionState}
                        // onChange={this.handleAccordionChange(
                        //   fields?.accordionState,
                        // )}
                      >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Box className="accordion-summary-content">
                            <Typography variant="subtitle1">
                              {fields?.title}
                            </Typography>
                            <Tooltip
                              title={
                                <Typography variant="body1">
                                  {fields?.info}
                                </Typography>
                              }
                            >
                              <InfoOutlinedIcon className="accordion-summary-info-icon" />
                            </Tooltip>
                          </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                          <FormGroupComponent
                            handleChange={this.handleChange}
                            formFields={fields}
                            values={formValue}
                          />
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </>
                ) : (
                  <FormGroupComponent
                    handleChange={this.handleChange}
                    handelKeyDown={this.handelKeyDown}
                    formFields={jsonFormField[activeStep]}
                    values={formValue}
                  />
                )}
              </Box>

              {activeStep === this.steps.length - 1 ? (
                <>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Radio
                      name="t_and_c_read"
                      value={formValue?.t_and_c_read}
                      type="checkbox"
                      id="t_and_c_read-id"
                      required
                      checked={formValue?.t_and_c_read === true}
                      onChange={(event) => {
                        const { target } = event;
                        const value =
                          target.type === 'checkbox'
                            ? target.checked
                            : target.value;
                        this.handleChange({
                          name: 't_and_c_read',
                          value,
                          error: !value,
                        });
                      }}
                      inputProps={{ 'aria-label': 'TERMS AND CONDITION' }}
                    />
                    <Typography variant="body2">
                      I have read the{' '}
                      <StyledLink
                        href="https://aidquest.com/termsandconditions/"
                        target="_blank"
                      >
                        Terms & Conditions
                      </StyledLink>
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    pt={2}
                    mb={1}
                  >
                    <AppButton
                      label="Back"
                      disabled={activeStep === 0}
                      handleClick={this.handleBack}
                    />
                    <AppButton
                      marginleft={1}
                      variant="contained"
                      color="primary"
                      disabled={
                        !validForm || !paymentValid || validOptionalField
                      }
                      label="Sign Up"
                      loading={loading}
                      loaderSize={24}
                      handleClick={this.formSubmitHandler}
                    />
                  </Box>
                </>
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  pt={2}
                  mb={1}
                >
                  <AppButton
                    label="Back"
                    disabled={activeStep === 0}
                    handleClick={this.handleBack}
                  />
                  <AppButton
                    marginleft={1}
                    label="Next"
                    variant="contained"
                    color="primary"
                    disabled={
                      !stepWiseValidForm[activeStep] || validOptionalField
                    }
                    handleClick={this.handleNext}
                  />
                </Box>
              )}
            </form>
            <Typography variant="subtitle1" align="center">
              Already an AidQuest member?
              <Link
                component={AppAdapterLink}
                to={APP_CONSTANTS.BASE_ROUTE_URLS.LOGIN}
                color="primary"
                style={{ marginLeft: 8 }}
              >
                Sign-in here.
              </Link>
            </Typography>
          </CardContent>
        </AppBasePageCard>
        <InfoModal
          title={dialogTitle}
          isOpen={dialogOpen}
          onClose={() => {
            if (this.state.registrationDone) {
              this.props.history.push(APP_CONSTANTS.BASE_ROUTE_URLS.LOGIN);
            } else {
              this.setState({
                dialogTitle: '',
                dialogOpen: false,
                dialogMsg: '',
              });
            }
          }}
        >
          {dialogMsg}
        </InfoModal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  timezones: state.MetaData.timezones,
  paymentOption: state.MetaData.paymentOption,
  serviceProvided: state.MetaData.serviceProvided,
  serviceProvidedADC: state.MetaData.serviceProvidedADC,
  serviceProvidedALF: state.MetaData.serviceProvidedALF,
  agencyType: state.MetaData.agencyType,
  isAuthenticated: state.Session.isAuthenticated,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      createAgency,
    },
    dispatch,
  );

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BasicRegistrationPage),
);
