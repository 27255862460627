import React, { useEffect, useMemo, useState } from 'react';
import './clientConversation.css';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';

export default function ClientConversationPreferences(props) {
  const { apiFormData, handleChange } = props;

  const [radioData, setRadioData] = useState({
    coverageAreaCheck: '',
    clientFollowUpTerm: '',
  });
  const [fieldsData, setFieldsData] = useState({
    otherFollowUp: '',
  });

  useEffect(() => {
    if (apiFormData) {
      setInitData(apiFormData);
    }
  }, [props]);

  const setInitData = (data) => {
    let apiData = data;
    const allowedVal = ['Care Manager', 'Client Care Coordinator'];
    if (
      Object.keys(apiData).includes('clientFollowUpTerm') &&
      !allowedVal.includes(data?.clientFollowUpTerm)
    ) {
      setFieldsData({
        ...fieldsData,
        otherFollowUp: apiData.clientFollowUpTerm,
      });
      apiData = { ...apiData, clientFollowUpTerm: 'Other' };
    }
    setRadioData({ ...radioData, ...apiData });
  };

  const handelRadioDataChange = (key, event) => {
    const keyToBeUsed = key;
    let val = event?.target?.value;
    const dataToSend = { ...radioData, [key]: val };
    setRadioData(dataToSend);
    if (key === 'clientFollowUpTerm' && val === 'Other') {
      val = fieldsData?.otherFollowUp;
    }
    if (key === 'clientFollowUpTerm' && val !== 'Other') {
      setFieldsData({
        ...fieldsData,
        otherFollowUp: '',
      });
    }
    const formErr = checkFormError(dataToSend, fieldsData);
    handleChange(keyToBeUsed, val, formErr);
  };

  const handelFieldDataChange = (key, event) => {
    const dataToSend = { ...fieldsData, [key]: event?.target?.value };
    setFieldsData(dataToSend);
    const formErr = checkFormError(radioData, dataToSend);
    handleChange('clientFollowUpTerm', event?.target?.value, formErr);
  };

  const checkFormError = (radioFormData, foeldFormData) => {
    let formError = false;
    if (
      radioFormData.clientFollowUpTerm === 'Other' &&
      !foeldFormData.otherFollowUp
    ) {
      formError = true;
    }
    return formError;
  };

  return useMemo(
    () => (
      <>
        <div className="parent-containers">
          <h2 style={{ color: '#5EC5BA' }}>
            Potential Client Conversation Preferences
          </h2>
          <div className="radio-container">
            <FormControl>
              <h3 className="radio-label">
                Choose one of the following for the coverage area check:
              </h3>
              <RadioGroup
                name="coverageAreaCheck"
                value={radioData.coverageAreaCheck}
                onChange={(event) => {
                  handelRadioDataChange('coverageAreaCheck', event);
                }}
              >
                <FormControlLabel
                  classes={{ label: 'form-control-label' }}
                  value="ZIP_CODE_AND_COVERAGE"
                  control={<Radio />}
                  label="Ask the visitors for zip code where care is needed. Check for coverage. If not covered, end chat."
                />
                <FormControlLabel
                  classes={{ label: 'form-control-label' }}
                  value="ZIP_CODE_AND_NO_COVERAGE"
                  control={<Radio />}
                  label={
                    <span>
                      Ask the visitors for zip code where care is needed. Do NOT
                      Check for coverage and continue chat.
                      <span className="label-note">
                        (Note: With this option you may receive leads outside of
                        your coverage area.)
                      </span>
                    </span>
                  }
                />
              </RadioGroup>
            </FormControl>

            <FormControl style={{ marginTop: '18px' }}>
              <h3 className="radio-label">
                What term would you like to use for following up with clients:
              </h3>
              <RadioGroup
                name="clientFollowUpTerm"
                value={radioData.clientFollowUpTerm}
                onChange={(event) => {
                  handelRadioDataChange('clientFollowUpTerm', event);
                }}
              >
                <FormControlLabel
                  classes={{ label: 'form-control-label' }}
                  value="Care Manager"
                  control={<Radio />}
                  label="Care Manager"
                />
                <FormControlLabel
                  classes={{ label: 'form-control-label' }}
                  value="Client Care Coordinator"
                  control={<Radio />}
                  label="Client Care Coordinator"
                />
                <div className="input-span" style={{}}>
                  <FormControlLabel
                    classes={{ label: 'form-control-label' }}
                    value="Other"
                    control={<Radio />}
                    label="Other"
                  />
                  {radioData.clientFollowUpTerm === 'Other' && (
                    <TextField
                      size="small"
                      error={
                        radioData.clientFollowUpTerm === 'Other' &&
                        !fieldsData.otherFollowUp
                      }
                      value={fieldsData.otherFollowUp}
                      onChange={(event) => {
                        handelFieldDataChange('otherFollowUp', event);
                      }}
                      label="Add your own term"
                      variant="outlined"
                    />
                  )}
                </div>
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      </>
    ),
    [radioData, fieldsData],
  );
}
